import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';
import { BREAKPOINTS } from 'constants/responsive';

type Props = {
  showTakeover?: boolean;
};

const FooterWrapper = styled('div')<Props>(({ showTakeover, theme }) => ({
  backgroundColor: theme.colors.gray['100'],
  margin: '0 auto',
  maxWidth: showTakeover
    ? theme.dimensions.pageWidthDesktop
    : BREAKPOINTS.X4LARGENEW,
  padding: showTakeover ? '0 17.5rem' : '0 3.2rem',
  position: 'relative',

  [mediaQueryBuilder(theme.mediaQueries.max.width['1024'])]: {
    padding: `0 ${theme.dimensions.gutter}`,
  },
}));

export default FooterWrapper;
