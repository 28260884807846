import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

type Props = {
  newFooter?: boolean;
};

export const Container = styled('footer')<Props>(
  ({ newFooter = false, theme }) => ({
    backgroundColor: theme.colors.gray['100'],
    overflowX: 'hidden',
    padding: '3rem 0 7rem 0',
    position: 'relative',

    [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
      paddingBottom: '11rem',
    },

    ...(newFooter
      ? {
          paddingBottom: '4rem',

          [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
            paddingBottom: '4rem',
          },
        }
      : {}),
  }),
);

export default Container;
