import capitalizeFirstLetter from 'utils/capitalizeFirstLetter';
import NavLink from 'components/NavLink';

type Props = {
  dataTest?: string;
  label: string;
  linkKey: string;
  localeTarget?: string;
  title: string;
  target?: string;
};

function FooterLink(props: Props) {
  const { localeTarget, linkKey, title, label, target, dataTest } = props;
  return localeTarget ? (
    <p key={linkKey}>
      <NavLink
        dataTest={dataTest}
        target={target}
        title={title}
        to={localeTarget}
      >
        {label || capitalizeFirstLetter(linkKey)}
      </NavLink>
    </p>
  ) : null;
}

export default FooterLink;
